import React, { createContext, useContext, useEffect, useState } from "react";
import { db } from '../config/firebase';
import { 
    collection,
    getDoc,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query,
    orderBy
 } from "firebase/firestore";

//  CREATE COLLECTION REFERENCES
const userCollection = collection(db, 'users_new');
const handoversCollection = collection(db, 'handovers');
const handoverItemsCollection = collection(db, 'h_items');
const preflightRiskAssessmentsCollection = collection(db, 'preflight_risk_assessments');
const preflightRiskAssessmentItemsCollection = collection(db, 'preflight_risk_assessment_items');
const aircraftCollection = collection(db, 'aircraft');
const stationsCollection = collection(db, 'station');




export const FirestoreContext = createContext();

export const FirestoreContextProvider = ({ children }) => {
    
    // GET USER COLLECTION  
    const getUsers = () => {
         return getDocs(userCollection);      
    }

    // GET HANDOVERS COLLECTION 
    // const getHandovers = () => {
    //     return getDocs(handoversCollection);
    // }

    const getHandovers = () => {
        return getDocs(query(handoversCollection, orderBy('created_on', 'desc')))
    }

    // GET SINGULAR HANDOVER 
    const getOneHandover = async (docId) => {
        // CREATE DOCUMENT REFERENCE 
        const handoverDocRef = doc(db, "handovers", docId);
        return await getDoc(handoverDocRef);
    }

    // GET HANDOVER ITEMS COLLECTION 
    const getHandoverItems = () => {
        return getDocs(handoverItemsCollection);
    }

    // GET PREFLIGHT RISK ASSESSMENTS 
    // const getPreflightRiskAssessments = () => {
    //     return  getDocs(preflightRiskAssessmentsCollection);
    // }
    const getPreflightRiskAssessments = () => {
        return getDocs(query(preflightRiskAssessmentsCollection, orderBy('createdOn', 'desc')))
    }

    // GET SINGULAR RISK ASSESSMENT 
    const getOnePreflightRiskAssessment = async (docId) => {
        // CREATE DOCUMENT REFERENCE 
        const preflightAssessmentRef = doc(db, "preflight_risk_assessments", docId);
        return await getDoc(preflightAssessmentRef)
    }

    // GET PREFLIGHT RISK ASSESSMENT ITEMS 
    const getPreflightRiskAssessmentItems = () => {
        return getDocs(preflightRiskAssessmentItemsCollection);
    }

    // GET AIRCRAFT DATA 
    const getAircraft = () => {
        return getDocs(aircraftCollection)
    }

    // GET STATION DATA 
    const getStations = () => {
        return getDocs(stationsCollection)
    }

    // GET USER DATA 
    const getCurrentUser = async (uid) => {
        // CREATE DOCUMENT REFERENCES 
        const currentUserRef = doc(db, "users_new", uid);
        return await getDoc(currentUserRef);
    }

    // DELETE HANDOVER
    const deleteHandover = async (docId) => {
        // CREATE DOCUMENT REFERENCE 
        const handoverDocRef = doc(db, "handovers", docId);
        return await deleteDoc(handoverDocRef);
    }


    return( 
        <FirestoreContext.Provider value={{
            getUsers, 
            getHandovers, 
            getOneHandover,
            getHandoverItems, 
            getPreflightRiskAssessments, 
            getOnePreflightRiskAssessment,
            getPreflightRiskAssessmentItems, 
            getStations, 
            getAircraft, 
            getCurrentUser,
            deleteHandover
        }}>
            { children }
        </FirestoreContext.Provider>
    )
}


export const DatabaseService = () => {
    return useContext(FirestoreContext);
}

