import { Link } from 'react-router-dom';
import moment from 'moment';
import { DatabaseService } from '../contexts/DatabaseContext';

const gridHandover = (props) => (
    <Link to={props.docId} className='text-blue-400 hover:underline'>
        <h2 className="">{props['incoming_officer']}</h2>
    </Link>
);

const deleteHandover = (props) => {
    const { deleteHandover } = DatabaseService(); // Get the delete function from FirestoreContext

    const handleDelete = async () => {
        if (window.confirm('Are you sure you want to delete this handover?')) {
            try {
                await deleteHandover(props.docId); // Pass the document ID to delete
                alert('Handover deleted successfully!');
                window.location.reload(); // Refresh the page after deletion
            } catch (error) {
                alert('Failed to delete handover: ' + error.message);
            }
        }
    };

    return (
        <button 
            onClick={handleDelete} 
            className="bg-red-400 text-white rounded-md p-1"
        >
            Delete
        </button>
    );
};

const timeTemplate = (props) => (
    <h3>{moment(props.created_on).format('MMM Do YYYY, h:mm:ss a')}</h3>
);

export const handoversGrid = [
    {
        headerText: 'Incoming', 
        width: '120',
        textAlign: 'Left',
        template: gridHandover
    },
    {
        field: 'departing_officer',
        headerText: 'Departing', 
        textAlign: 'Left',
        width: '120'
    },
    {
        field: 'aircraft',
        headerText: 'Aircraft', 
        textAlign: 'Left',
        width: '120'
    },
    {
        field: 'created_on',
        headerText: 'Date', 
        textAlign: 'Left',
        width: '120',
        template: timeTemplate
    },
    {
        field: 'station',
        headerText: 'Station', 
        textAlign: 'Left',
        width: '120'
    },
    {
        field: 'handover_status',
        headerText: 'Status', 
        textAlign: 'Left',
        width: '120'
    },
    // {
    //     headerText: 'Delete', 
    //     textAlign: 'Left',
    //     width: '120',
    //     template: deleteHandover
    // },
];
